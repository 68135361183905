import { useEffect, useMemo, useRef, useState } from 'react'
import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import Badge from 'components/badge'
import { PRODUCT_SKU } from 'helpers/constants/cart'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import useOutsideClick from 'helpers/hooks/useOutsideClick'
import { ProductOfferProps } from 'types/pdp/product-offer'
import { useAccount, useCart } from 'frontastic'
import Image from 'frontastic/lib/image'

const TEST_DEVICE_OPTION = 0
const NORMAL_DEVICE_OPTION = 1

const ProductOffer: React.FC<ProductOfferProps> = ({
  tasticData,
  product,
  testDeviceOffer,
  prodBundles,
  onSelectVariant,
}) => {
  const { account, isValidating, loggedIn } = useAccount()
  const { data: cart, isValidating: isCartValidating } = useCart()

  const [openTooltip, setOpenTooltip] = useState(false)
  const [hoverTooltip, setHoverTooltip] = useState(false)
  const tooltipRef = useRef()
  useOutsideClick(tooltipRef, () => {
    setOpenTooltip(false)
  })

  const [isTestDeviceOptionActive, setIsTestDeviceOptionActive] = useState(true)
  const [selectedIndex, setSelectedIndex] = useState<number>(TEST_DEVICE_OPTION)

  useEffect(() => {
    if (isValidating || isCartValidating) {
      return
    }
    const isTestDeviceOfferActive = !loggedIn || (account && account?.isNewCustomer)

    if (cart?.lineItems?.length) {
      const singleNerivio = cart?.lineItems?.find((lineItem) => lineItem?.variant?.sku === PRODUCT_SKU)

      // if single product in cart or user is not new customer, disable test device option
      if (singleNerivio || !isTestDeviceOfferActive) {
        setIsTestDeviceOptionActive(false)
        setSelectedIndex(NORMAL_DEVICE_OPTION)
      } else {
        setIsTestDeviceOptionActive(true)
        setSelectedIndex(TEST_DEVICE_OPTION)
      }
    } else {
      setIsTestDeviceOptionActive(isTestDeviceOfferActive)
      if (!isTestDeviceOfferActive) {
        setSelectedIndex(NORMAL_DEVICE_OPTION)
      }
    }
  }, [isValidating, isCartValidating, cart?.lineItems?.length, account, loggedIn, cart?.lineItems])

  const testDataPrice = useMemo(() => {
    if (testDeviceOffer) {
      // const discountedAmount =
      //   testDeviceOffer?.discount?.amount_off ??
      //   (testDeviceOffer?.discount?.percent_off * product?.price?.centAmount) / 100 ??
      //   0
      const price = product?.price?.centAmount - 0
      const priceInString = CurrencyHelpers.formatForCurrency(price)
      const pricePerUseInString = CurrencyHelpers.formatForCurrency(
        price / ((product?.attributes?.numberOfUsage as number) || 1),
      )
      return {
        priceInString: priceInString,
        pricePerUseInString: pricePerUseInString,
      }
    }
    return null
  }, [testDeviceOffer, product?.price?.centAmount, product?.attributes?.numberOfUsage])

  const originalPricePerUsage = CurrencyHelpers.formatForCurrency(
    product?.price?.centAmount / ((product?.attributes?.numberOfUsage as number) || 1),
  )
  const originalProductPrice = CurrencyHelpers.formatForCurrency(product?.price?.centAmount)

  const tabPanels = useMemo(() => {
    const tasticContent =
      tasticData?.offerContent?.length && prodBundles?.length && tasticData?.bundleOfferContent?.length
        ? tasticData?.offerContent.concat(tasticData?.bundleOfferContent)
        : tasticData?.offerContent

    const initialOfferContent = tasticContent?.map((offer, index) => ({
      key: `offer-content-${index}`,
      component: (
        <>
          <div className="flex flex-col-reverse items-start gap-4 lg:flex-row lg:items-center lg:justify-between">
            <div className="text-lg font-bold">
              {offer?.isTestDeviceOffer ? offer?.testDeviceOfferName : offer?.offerName || product?.name}
            </div>
            {offer?.flagLabel && (
              <Badge media={offer?.flagIcon?.media} label={offer?.flagLabel} className="whitespace-nowrap" />
            )}
          </div>

          <div className="mt-2 whitespace-pre-line text-sm">{offer?.description}</div>
        </>
      ),
    }))

    return initialOfferContent
  }, [tasticData?.offerContent, tasticData?.bundleOfferContent, prodBundles?.length, product?.name])

  const handleSelectTab = (index: number) => {
    if (index === TEST_DEVICE_OPTION && !isTestDeviceOptionActive) {
      return
    }

    setSelectedIndex(index)
    if (index === TEST_DEVICE_OPTION || index == NORMAL_DEVICE_OPTION) {
      // Always use master variant
      onSelectVariant(product?.variants?.[0])
    } else {
      onSelectVariant(prodBundles?.[index - 2]?.variants?.[0])
    }
  }

  const onClickTooltip = (event) => {
    event.stopPropagation()
    setOpenTooltip(true)
  }

  const onCloseTooltip = (event) => {
    event.stopPropagation()
    setOpenTooltip(false)
    setHoverTooltip(false)
  }

  const onHoverTooltip = () => {
    setHoverTooltip(true)
  }

  const onLeavingTooltip = () => {
    setHoverTooltip(false)
  }

  const initialOffer = useMemo(() => {
    return tasticData?.offerContent
      ?.filter((item) => !item.isTestDeviceOffer)
      .map((offer, index) => {
        const isTestOffer = offer?.isTestDeviceOffer
        return (
          <Tab
            data-cy={isTestOffer ? `test-device-offer` : `normal-device-offer-${index}`}
            key={`product-offer-${index}`}
            aria-disabled={isTestOffer && !isTestDeviceOptionActive}
            className={classNames('option-button', {
              // disabled: isTestOffer && !isTestDeviceOptionActive,
              'has-out-of-box': offer?.isTestDeviceOffer && tasticData?.cashbackMessage,
              'sm:col-span-3 lg:col-span-1 lg:mb-6 xl:col-span-3': offer?.isTestDeviceOffer,
            })}
          >
            <div className="option-heading">
              {!!offer?.offerTooltip && isTestOffer && !isTestDeviceOptionActive && (
                <div
                  onMouseEnter={onHoverTooltip}
                  onMouseLeave={onLeavingTooltip}
                  className={classNames(
                    'tooltip relative z-[2]',
                    // {
                    //   "show pointer-events-none": openTooltip
                    // }
                  )}
                >
                  <div className="tooltip-icon absolute top-0 right-0 bg-right-top" onClick={onClickTooltip}></div>
                  <div
                    className={classNames('tooltip-content top-2 !left-0 !right-0 !w-auto lg:!right-6 lg:!max-w-none', {
                      '!hidden': !openTooltip && !hoverTooltip,
                      '!block': openTooltip || hoverTooltip,
                    })}
                  >
                    <div className="tooltip-close" onClick={onCloseTooltip}></div>
                    <div
                      className="whitespace-pre-line"
                      ref={tooltipRef}
                      dangerouslySetInnerHTML={{ __html: offer?.offerTooltip }}
                    />
                  </div>
                </div>
              )}

              <div className="mb-1 text-sm font-bold lg:text-lg" data-cy="device-name">
                {isTestOffer ? offer?.testDeviceOfferName : product?.name}
              </div>
              <div className="mx-auto flex items-center gap-2">
                <div data-cy="price-per-usage" className={classNames('text-lg font-bold lg:text-xl')}>
                  {isTestOffer ? testDataPrice?.pricePerUseInString : originalPricePerUsage}
                </div>

                {/* {isTestOffer && (
                  <div
                    className={classNames('text-lg lg:text-base', {
                      'line-through opacity-50': isTestOffer,
                    })}
                  >
                    {originalPricePerUsage}
                  </div>
                )} */}
              </div>

              <div className="text-xs">{tasticData?.perMigraineTreatment}</div>

              <div className="mx-auto mt-2 flex items-center gap-1">
                <div data-cy="unit-price" className="text-base font-bold">
                  {isTestOffer ? testDataPrice?.priceInString : originalProductPrice}
                </div>
                {/* {isTestOffer && <div className="text-xs line-through opacity-50">{originalProductPrice}</div>} */}
              </div>
            </div>
          </Tab>
        )
      })
  }, [
    tasticData?.offerContent,
    tasticData?.cashbackMessage,
    tasticData?.perMigraineTreatment,
    isTestDeviceOptionActive,
    openTooltip,
    hoverTooltip,
    product?.name,
    testDataPrice?.pricePerUseInString,
    testDataPrice?.priceInString,
    originalPricePerUsage,
    originalProductPrice,
  ])

  const prodBundleOffer = useMemo(
    () =>
      prodBundles?.map((product, index) => (
        <Tab data-cy={`bundle-offer-${index}`} key={`bundle-offer-${index}`} className={classNames('option-button')}>
          <div className="option-heading">
            <div className="mb-1 text-sm font-bold lg:text-lg" data-cy="device-name">
              {!!product?.attributes?.pdpName
                ? product?.attributes?.pdpName
                : product?.attributes?.prefixProductName
                ? product?.name?.replace(product?.attributes?.prefixProductName, '').trim()
                : product?.name}
            </div>
            <div className="mx-auto flex items-center gap-2">
              {!!product?.attributes?.numberOfUsage && (
                <div data-cy="price-per-usage" className={classNames('text-lg font-bold lg:text-xl')}>
                  {CurrencyHelpers.formatForCurrency(product?.price?.centAmount / product?.attributes?.numberOfUsage)}
                </div>
              )}
            </div>

            <div className="text-xs">{tasticData?.perMigraineTreatment}</div>

            <div className="mx-auto mt-2 flex items-center gap-1">
              <div data-cy="unit-price" className="text-base font-bold">
                {CurrencyHelpers.formatForCurrency(product?.price?.centAmount)}
              </div>
              {!!product?.price?.custom?.recommended?.centAmount && (
                <div className="text-xs line-through opacity-50">
                  {CurrencyHelpers.formatForCurrency(product?.price?.custom?.recommended?.centAmount)}
                </div>
              )}
            </div>
          </div>
        </Tab>
      )),
    [prodBundles, tasticData?.perMigraineTreatment],
  )

  console.log(initialOffer)

  return (
    <div className="product__options">
      <Tab.Group selectedIndex={selectedIndex} onChange={handleSelectTab}>
        <div
          className={classNames('option-list', {
            'without-bundles': !tasticData?.isBundlesOffer,
          })}
        >
          <Tab.List className="option-button-wrapper">
            {initialOffer}
            {tasticData?.isBundlesOffer && prodBundleOffer}
          </Tab.List>
        </div>

        <Tab.Panels className="option-detail">
          {tabPanels?.map((panel) => (
            <Tab.Panel key={panel.key}>{panel.component}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default ProductOffer
